<template>
  <div id="invoice-page">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center ml-auto">
          <!-- <vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button> -->
          <vs-button class="mb-base mr-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">{{ $t('print') }}</vs-button>
        </div>
      </div>

      <vx-card id="invoice-container">

          <div class="vx-row p-4">
              <div class="vx-col w-1/2">
                  <img src="@/assets/images/logo/logo.png" alt="vuexy-logo">
                  <h1>SET Market</h1>
                  <vs-chip :color="order.status === 'pending' ? 'warning' : 'primary'" class="text-lg">{{ $t(`orderPage.${order.status}`) }}</vs-chip>
              </div>
              <div class="vx-col w-1/2 text-right">
                  <h1>{{ $t('invoice') }}: #{{ order.code }}</h1>
                  <div class="invoice__invoice-detail mt-6">
                      <h6>{{ $t('date') }}</h6>
                      <!-- <p>#{{ order.code }} / {{ order.createdAt }}</p> -->
                      <p>{{ order.createdAt }}</p>
                      <h6 class="mt-4">{{ $t('deliveryTime') }}</h6>
                      <p>{{ order.delivery_time }}</p>
                  </div>
              </div>
              <div class="vx-col w-1/2">
                  <h5>{{ $t('supplier') }}</h5>
                  <div class="invoice__recipient-info my-2">
                    <h4>{{ order.supplier.name }} / {{ order.supplier.company_name }}</h4>
                  </div>
              </div>
          </div>

          <div class="p-4" v-if="order.supplierorder_products.length">
              <vs-table hoverFlat :data="order.supplierorder_products">
                  <template slot="thead">
                      <vs-th class="pointer-events-none">#</vs-th>
                      <vs-th class="pointer-events-none">{{ $t('fields.code') }}</vs-th>
                      <vs-th class="pointer-events-none">{{ $t('fields.title') }}</vs-th>
                      <vs-th class="pointer-events-none">{{ $t('fields.amount') }}</vs-th>
                      <vs-th class="pointer-events-none">{{ $t('fields.price') }}</vs-th>
                      <vs-th class="pointer-events-none">{{ $t('fields.total') }}</vs-th>
                  </template>
                  <template slot-scope="{data}">
                      <vs-tr v-for="(tr, index) in data" :key="index">
                          <vs-td>{{ index + 1 }}</vs-td>
                          <vs-td>{{ data[index].code }}</vs-td>
                          <vs-td :data="data[index].task">{{ data[index][`name_${$i18n.locale}`] }} {{ data[index].weight }}</vs-td>
                          <vs-td :data="data[index].hours">{{ data[index]['Soproducts'].quantity }}</vs-td>
                          <vs-td :data="data[index].rate">{{ data[index].rate }}_______  TMT</vs-td>
                          <vs-td :data="data[index].amount">{{ data[index].amount }}_______  TMT</vs-td>
                      </vs-tr>
                  </template>
              </vs-table>
              <vs-table hoverFlat class="w-1/2 ml-auto mt-4" :data="[]">
                  <vs-tr>
                      <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('fields.subtotal') }}</span></vs-th>
                      <vs-td>____________ TMT/USD</vs-td>
                  </vs-tr>
                  <vs-tr>
                      <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('discount.d') }} ( ____ %)</span></vs-th>
                      <vs-td>____________ TMT/USD</vs-td>
                  </vs-tr>
                  <vs-tr>
                      <vs-th class="pointer-events-none"><span class="uppercase">{{ $t('fields.total') }}</span></vs-th>
                      <vs-td>____________ TMT/USD</vs-td>
                  </vs-tr>
              </vs-table>
          </div>

          <!-- INVOICE FOOTER -->
          <div class="invoice__footer text-right p-4">
              <p class="mb-4">{{ new Date().toLocaleDateString("ru-RU") }} &copy; Set Market</p>
              <!-- <p>
                  <span class="mr-8">BANK: <span class="font-semibold">FTSBUS33</span></span>
                  <span>IBAN: <span class="font-semibold"> G882-1111-2222-3333 </span></span>
              </p> -->
          </div>
      </vx-card>
    </template>
  </div>
</template>

<script>

export default{
  data () {
    return {
      mailTo: '',
      order: {},
      isLoading: true
    }
  },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      await this.$http.get('/suppliers/order-products', { params: { supplierorderId: this.$route.params.id } }).then(response => {
        this.order = response.data
        this.isLoading = false
        this.$emit('setAppClasses', 'invoice-page')
      }).catch(() => { this.isLoading = false })
    },
    printInvoice () { window.print() }
  }
}
</script>

<style lang="scss">
.not-data-table {
  visibility: hidden !important ;
}
@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }
    .not-data-table {
      visibility: hidden !important ;
    }
    .p-4 {
      padding: 5px 0 !important;
    }
    .vx-card__body {
      padding: 0!important;
    }
    #content-area {
      margin: 0 !important;
    }

    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
@page {
  size: auto;
}
</style>